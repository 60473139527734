"use client"
import { gql } from "graphql-request";
import { useFilminfo } from "src/lib/client/useFilminfo";
import { Maybe, WatchableContentType } from "src/lib/movieinfo/movieinfotypes";
import { useDebugValue, useEffect, useRef, useState } from "react";

type UseSearchOptions = {
	streamingOnly?: boolean;
	maxNumItems?: number;
	includeLocations?: boolean;
	inputDelay?: number;
	active?: boolean;
}

export default function useSearch(searchText?: string, opts?: UseSearchOptions) {
	const { streamingOnly = false, maxNumItems = 10, includeLocations = true, inputDelay = 400, active = true } = opts ?? {};

	useDebugValue(searchText, searchText => `searchText: ${searchText}`);
	useDebugValue(streamingOnly, streamingOnly => `streamingOnly: ${streamingOnly}`);
	useDebugValue(maxNumItems, maxNumItems => `maxNumItems: ${maxNumItems}`);
	useDebugValue(includeLocations, includeLocations => `includeLocations: ${includeLocations}`);
	useDebugValue(inputDelay, inputDelay => `inputDelay: ${inputDelay}`);
	useDebugValue(active, active => `active: ${active}`);

	const [_searchText, setSearchText] = useState("");
	const { fiLoading, fiLoaded, fiData } = useFilminfo(includeLocations ? SEARCH_QUERY_WITH_LOC : SEARCH_QUERY, { searchText: _searchText?.trim(), streamingOnly, maxNumItems }, { active: active && !!_searchText?.trim() });
	const searchTimer = useRef<any>(null);

	useEffect(() => {
		// make sure a new search is not triggered until the input delay has fired
		if (searchText?.trim() && searchText.trim() !== _searchText) {
			if (searchTimer.current) {
				clearTimeout(searchTimer.current);
				searchTimer.current = null;
			}
			if (inputDelay > 0) {
				searchTimer.current = setTimeout(() => {
					setSearchText(searchText.trim());
				}, inputDelay);
			} else {
				setSearchText(searchText.trim());
			}
		} else if (!searchText?.trim()) {
			if (searchTimer.current) {
				clearTimeout(searchTimer.current);
				searchTimer.current = null;
			}
			setSearchText("");
		}
		return () => {
			if (searchTimer.current) {
				clearTimeout(searchTimer.current);
				searchTimer.current = null;
			}
		}
	}, [searchText, _searchText, inputDelay]);

	return {
		searchLoading: fiLoading,
		searchLoaded: fiLoaded,
		searchResult: fiData?.searchForWatchableContent?.filter(resultFilter) || [],
		locationResult: includeLocations ? fiData?.cinemaQuery?.searchForLocations?.map(l => l!.name) || [] : null
	};
}

function resultFilter(watchable: Maybe<WatchableContentType>) {
	return watchable?.movieId || watchable?.streamingContentId;
}

const SEARCH_QUERY_WITH_LOC = gql`
query searchQuery($searchText: String, $streamingOnly: Boolean, $maxNumItems: Int) {
	searchForWatchableContent(searchText: $searchText, streamingOnly: $streamingOnly, maxNumItems: $maxNumItems) {
		__typename
		movieId
		streamingContentId
		title
		productionYear
		isCinemaRelevant

		isSeries
		sanityImageWideUrl
        sanityImagePosterUrl
		imageLandscapeStreaming
		imagesLandscapeStreaming {
			__typename
			height
			width
			url
		}
		imagePosterStreaming
		imagesPosterStreaming {
			__typename
			width
			height
			url
		}
	}
	cinemaQuery {
		searchForLocations(searchText: $searchText) {
			name
		}
	}
}
  `;

const SEARCH_QUERY = gql`query searchQuery($searchText: String, $streamingOnly: Boolean, $maxNumItems: Int) {
	searchForWatchableContent(searchText: $searchText, streamingOnly: $streamingOnly, maxNumItems: $maxNumItems) {
		__typename
		movieId
		streamingContentId
		title
		productionYear
		isCinemaRelevant

		isSeries
		imagesLandscapeCinema {
		  format
		  height
		  width
		  url
		}
		imagesPosterCinema {
		  width
		  height
		  url
		}
		imageLandscapeStreaming
		imagesLandscapeStreaming {
		  height
		  width
		  url
		}
		imagePosterStreaming
		imagesPosterStreaming {
		  width
		  height
		  url
		}
	}
  }

  `;
