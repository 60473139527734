import Link from 'next/link';
import React from 'react';
//#region [Styles]

//#endregion

//#region [Props]
type aProps = Omit<React.ComponentProps<"a">, "href" | "target" | "children" | "className">;
type FilmwebLinkProps = {
	to: string;
	className?: string;
	target?: string;
	children: React.ReactNode;
} & aProps;
//#endregion



//#region [Component]
// TODO: Trenger vi å tenke på scroll restoration?
export default function FilmwebLink({ to, className, target, children, ...rest }: FilmwebLinkProps) {
	if (!to) {
		// if it is an empty string
		return (
			<div className={className}>
				{children}
			</div>
		);
	}

	if (isInternalLink(to)) {
		const relativeUrl = makeRelative(to);
		return <Link href={relativeUrl} legacyBehavior>
			<a className={className} {...rest}>
				{children}
			</a>
		</Link>

	}
	return <a href={to} className={className} target={target} {...rest}>
		{children}
	</a>

}
//#endregion

//#region [Other]
function makeRelative(url: string): string {
	const res = /^(?:(?:https?:)?\/\/(?:www|stage|local|(?:.*?.dev))\.filmweb.no)?(\/.*)/.exec(url);
	if (res?.length ?? 0 >= 2) {
		return res![1];
	}
	return url;
}


function isInternalLink(url: string): boolean {
	return /^(?:(?:https?:)?\/\/(?:www|stage|local|(?:.*?.dev))\.filmweb.no)?(\/.*)/.test(url);
}

export function createUrl(isCinemaRelevant: boolean, movieId?: string, streamingId?: number | string, isSeries?: boolean) {
	if (isCinemaRelevant && movieId) {
		return `/film/${movieId}`;
	}

	return `/streamingguide/${isSeries ? "serie" : "film"}/${streamingId}`;
}

//#endregion