import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request";
import { envConfig } from "src/lib/client/envConfig";
import { MovieInfoQuery } from "src/lib/movieinfo/movieinfotypes";
import { useDebugValue } from "react";

const graphQLClient = new GraphQLClient(envConfig.NEXT_PUBLIC_FILMINFO_ENDPOINT, { method: "GET" });

export type FilmInfoOptions = {
	active?: boolean;
	cacheTime?: number;
	staleTime?: number;
}

type UseFilminfoResult = {
	fiLoading: boolean;
	fiLoaded: boolean;
	fiData?: MovieInfoQuery;


}

/**
 * Custom hook for requesting data from Filminfo.
 */
export function useFilminfo(query: string, params?: any, options?: FilmInfoOptions): UseFilminfoResult {

	const result = useQuery({
		queryKey: buildFilminfoQueryKey(query, params),
		queryFn: filminfoRequest,
		enabled: options?.active ?? true,
		refetchOnWindowFocus: false,
		staleTime: options?.staleTime
	});

	useDebugValue(query, query => query);
	useDebugValue(params, params => params);
	useDebugValue(options, options => options);
	useDebugValue(result.data, fiData => fiData);
	useDebugValue(result.isFetching, fiLoading => `fiLoading: ${fiLoading}`);
	useDebugValue(result.isFetched, fiLoading => `fiLoaded: ${fiLoading}`);


	return { fiLoading: options?.active ? result.isFetching : false, fiLoaded: result?.isFetched ?? false, fiData: result?.data };
}


export async function filminfoRequest(context: QueryFunctionContext): Promise<MovieInfoQuery> {
	const [_key, { query, params }] = context.queryKey as any[];
	const movieInfoQSResult = await graphQLClient.request<MovieInfoQuery>(query, params ?? null);
	return movieInfoQSResult;

}

export function buildFilminfoQueryKey(query: string, params?: any): unknown[] {
	let keyObj: any = { query };
	if (params) {
		keyObj.params = params;
	}
	return ["filminfo", keyObj];
}