import WatchlistSvg from "src/components/svg/WatchlistSvg";
import theme from "src/components/themes/DefaultTheme";
import { useUserContext } from "src/lib/contexts/UserContext";
import useWatchlist from "src/lib/filmwebid/useWatchlist";
import { MovieClipType, MovieType, StreamingContentType, WatchableContentType } from "src/lib/movieinfo/movieinfotypes";
import styled from "src/lib/styles/css";
import { IFilmomtale } from "src/lib/types/Filmomtale";
import React, { useMemo } from "react";

//#region [Styles]
const SWithWatchList = styled.div`
	position: relative;
	z-index: 0;
`;

const SWithWatchlistButton = styled.button`
	position: absolute;
	width: 44px;
	height: 44px;
	display: block;
	top: 6px;
	right: 6px;
	border: none;
	background: ${theme.palette.lillaHovedfarge};
	padding: 0;
	margin: 0;
	z-index: 2;
	border-radius: 50%;
`;

const SWatchlistSvg = styled(WatchlistSvg)`
	display: block;
	width: 40%;
	height: auto;
	margin: 0 auto;
`;
//#endregion

//#region [Props]
type DefaultOptionsType = {
	onComplete?: () => void;
};

type WithWatchlistProps = {
	wlcClassName?: string;
	wlcButtonClassName?: string;
	wlcStyle?: React.CSSProperties;
};
//#endregion

/**
 * This HOC takes a component and adds watchlisting functionality as a button in the top-right corner.
 * The enhanced component will have some extra props added:
 * - wlcClassName: A classname for the withWatchlist container
 * - wlcButtonClassName: Any extra classes for the button
 * @param {Component} WrappedComponent The component to enhance
 * @param {string} watchableIdsParent The path to where movieId and/or streamingContentId can be found
 * @param {function} onComplete callback for when watchlist status toggles
 * @returns a new watchlistable component
 */
//#region [Component]
function withWatchlist<P>(WrappedComponent: React.ComponentType<P>, watchableIdsParent: string, options?: DefaultOptionsType) {
	const EnhancedComponent = (props: WithWatchlistProps & P) => {
		const userContext = useUserContext();
		const edi = useMemo(() => {
			const film: MovieType | WatchableContentType | IFilmomtale | MovieClipType | undefined = (props as any)?.[watchableIdsParent];
			return getEdi(film) ?? null;

		}, [props]);

		const streamingId = useMemo(() => {
			const film: MovieType | WatchableContentType | IFilmomtale | StreamingContentType | undefined = (props as any)?.[watchableIdsParent];
			return getStreamingId(film);
		}, [props]);

		const [watchlister, isWatchlisting] = useWatchlist({ edi, streamingId, onComplete: options?.onComplete });

		const isWatchlisted = useMemo(() => {
			return userContext.watchlistedList.some(item => (edi && item.edi && item.edi === edi) || (streamingId && item.streamingId && item.streamingId === streamingId));
		}, [userContext.watchlistedList, edi, streamingId]);

		return <SWithWatchList className={props.wlcClassName} style={props.wlcStyle}>
			{!!(edi || streamingId) && <SWithWatchlistButton disabled={isWatchlisting} className={props.wlcButtonClassName} title="Legg filmen/serien i huskelisten" aria-label="Legg filmen/serien i huskelisten" onClick={() => watchlister(!isWatchlisted)}>
				<SWatchlistSvg selected={isWatchlisted} />
			</SWithWatchlistButton>}
			<WrappedComponent {...props} />
		</SWithWatchList>

	}
	EnhancedComponent.displayName = `WithWatchlist(${getDisplayName(WrappedComponent)})`;
	return EnhancedComponent;
};

export default withWatchlist;
//#endregion

//#region [Other]
function getDisplayName<P>(WrappedComponent: React.ComponentType<P>) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
//#endregion

function getEdi(film: MovieType | WatchableContentType | IFilmomtale | MovieClipType | undefined): string | null {
	if (film) {
		switch (film.__typename) {
			case "MovieType":
				return (film as MovieType).mainVersionId;
			case "MovieClipType":
				return (film as MovieClipType).movieId;
			case "WatchableContentType":
				return (film as WatchableContentType).movieId;
			default:
				return (film as IFilmomtale).mainVersionEDI;
		}
	}
	return null;
}

function getStreamingId(film: MovieType | WatchableContentType | IFilmomtale | StreamingContentType | undefined): number | null {
	if (film) {
		switch (film.__typename) {
			case "MovieType":
				return null;
			case "WatchableContentType":
				return (film as WatchableContentType).streamingContentId;
			case "StreamingContentType":
				return (film as StreamingContentType).id;
			default:

		}
	}
	return null;
}