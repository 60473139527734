"use client"
import DropDownMenu from 'src/components/menu/DropDownMenu';
import MainMenuButton from 'src/components/menu/MainMenuButton';
import Search from 'src/components/search/Search';
import SearcResult from 'src/components/menu/SearchResult';
import { ISearchAction } from 'src/components/search/ISearchAction';
import { IMenuItem } from 'src/lib/types/Settings';
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useReducer } from 'react';

const DynamicLoginButton = dynamic(() =>
	import('src/components/menu/LoginButton').then((mod) => mod.default),
	{ ssr: false }
);

const DynamicFilmwebIdDropDownMenu = dynamic(() =>
	import("src/components/menu/FilmwebIdDropDownMenu").then(mod => mod.default),
	{ ssr: false }
);

//#region [Styles]

//#endregion

//#region [Props]
type SearchAndDropdownMenuProps = {
	menuData?: IMenuItem[]
};
//#endregion

//#region [Component]
export default function SearchAndDropdownMenu({ menuData }: SearchAndDropdownMenuProps) {
	const [state, dispatch] = useReducer(menuReducer, EMPTY_STATE);
	const router = useRouter();

	useEffect(() => {
		// when next.js reports the route is about to change, close the menu
		const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
			dispatch({ type: "CLEAR_SEARCH" });
		};

		router.events.on('routeChangeStart', handleRouteChange);
		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		}
	}, [router]);

	return <>
		<Search showSearchResults={state.showSearchResults} dispatch={dispatch} searchExpression={state.searchExpression} />
		<SearcResult searchExpression={state.searchExpression} showSearchResult={state.showSearchResults} />
		<DynamicLoginButton onClickIfAuthenticated={() => dispatch({ type: 'TOGGLE_FILMID_MENU' })} />
		<MainMenuButton dispatch={dispatch} />
		<DropDownMenu menuItems={menuData} isVisible={state.showDropdownmenu} />
		<DynamicFilmwebIdDropDownMenu dispatch={dispatch} isVisible={state.showFilmIdMenu} />
	</>;
}
//#endregion

//#region [Other]
const EMPTY_STATE: IMenuState = {
	showSearchResults: false,
	showDropdownmenu: false,
	showFilmIdMenu: false,
	searchExpression: "",
	hasFiredFirstFocus: false
};

interface IMenuState {
	showSearchResults: boolean;
	showDropdownmenu: boolean;
	showFilmIdMenu: boolean;
	searchExpression: string;
	hasFiredFirstFocus: boolean;
}

export interface IMenuAction extends Omit<ISearchAction, "type"> {
	type: "BEGIN_SEARCH" | "END_SEARCH" | "CLEAR_SEARCH" | "DO_SEARCH" | "TOGGLE_MENU" | "TOGGLE_FILMID_MENU" | "CLOSE_MENU";
}

function menuReducer(state: IMenuState, action: IMenuAction): IMenuState {
	switch (action.type) {
		case 'BEGIN_SEARCH':
			if (!state.hasFiredFirstFocus) {
				trackSearchStart();
			}
			return { ...state, showSearchResults: true, showDropdownmenu: false, showFilmIdMenu: false, hasFiredFirstFocus: true };
		case 'END_SEARCH':
			return { ...state, showSearchResults: false };
		case 'CLEAR_SEARCH':
			return { ...state, showDropdownmenu: false, showSearchResults: false, showFilmIdMenu: false, searchExpression: "" };
		case 'DO_SEARCH':
			return { ...state, searchExpression: action.searchExpression ?? "", showSearchResults: true, showDropdownmenu: false, showFilmIdMenu: false };
		case 'TOGGLE_MENU':
			if (state.showDropdownmenu) {
				return { ...state, showSearchResults: false, showDropdownmenu: false, showFilmIdMenu: false };
			} else {
				trackMenuOpen();
				return { ...state, showSearchResults: false, showDropdownmenu: true, showFilmIdMenu: false };
			}
		case 'TOGGLE_FILMID_MENU':
			return { ...state, showFilmIdMenu: !state.showFilmIdMenu, showDropdownmenu: false, showSearchResults: false };
		case 'CLOSE_MENU':
			return { ...state, showSearchResults: false, showDropdownmenu: false, searchExpression: "" };
		default:
			return state;
	}
}

function trackMenuOpen() {
	if (typeof window !== "undefined") {
		window.dataLayer?.push({
			'event': 'fw_Menu',
			'eventCategory': 'Meny',
			'eventAction': "Open"
		});
	}
}

function trackSearchStart() {
	if (typeof window !== "undefined") {
		window.dataLayer?.push({
			'event': 'fw_Quicksearch',
			'eventCategory': 'Hurtigsøk',
			'eventAction': 'Start hurtigsøk'
		});
	}
}
//#endregion