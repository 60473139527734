import ResultLandscapeImage from 'src/components/search/ResultLandscapeImage';
import ResultPoster from 'src/components/search/ResultPoster';
import { ImageVersionType, WatchableContentType } from 'src/lib/movieinfo/movieinfotypes';
//#region [Styles]

//#endregion

//#region [Props]
type SearchContentItemProps = {
	content: WatchableContentType;
	forceStreaming?: boolean;
	className?: string;
	clickTracker?: (id: string | number, title: string) => void;
};
//#endregion

//#region [Component]
export default function SearchContentItem({ content, className, clickTracker, forceStreaming = false }: SearchContentItemProps) {
	const displayFormat = determineImagePresentation(content);

	if (displayFormat === "landscape") {
		return (<ResultLandscapeImage key={content.streamingContentId || content.movieId}
			forceStreaming={forceStreaming}
			className={className}
			data={content}
			clickTracker={clickTracker}
		/>);
	}

	return (<ResultPoster key={content.streamingContentId || content.movieId}
		forceStreaming={forceStreaming}
		className={className}
		data={content}
		clickTracker={clickTracker}
	/>);
}
//#endregion

//#region [Other]
function determineImagePresentation(content: WatchableContentType): "landscape" | "poster" {
	if (content) {
		if (content.sanityImagePosterUrl || (content.imagesPosterStreaming?.length ?? 0) > 0) return "poster";
		if (content.sanityImageWideUrl || (content.imagesLandscapeStreaming?.length ?? 0) > 0 || content.imageLandscapeStreaming) return "landscape";
	}
	return "poster";
}
//#endregion