import LocationResultList from 'src/components/locationresultlist/LocationResultList';
import ResultPosterRow from 'src/components/search/ResultPosterRow';
import SuspenseFallback from 'src/components/suspensefallback/SuspenseFallback';
import useSearch from 'src/lib/client/useSearch';
import { WatchableContentType } from 'src/lib/movieinfo/movieinfotypes';
import { rgba } from 'polished';
import { useCallback } from 'react';
import styled from "src/lib/styles/css";
import theme from 'src/components/themes/DefaultTheme';
//#region [Styles]
const SSearchResult = styled.div`
	position: absolute;
	top: 55px;
	left: 0;
	right: 0;
	//transform: scaleY(1);
	//transform-origin: top;
	background-color: ${rgba(theme.mainColor, theme.generalAlpha)};
	color: ${theme.textColor};
	z-index: 101;
	overflow: hidden;
	transition: transform 0.2s ease-in-out;
`;

const SSearchResultsList = styled.div`
	width: 100%;
	max-width: ${theme.maxContentWidth}px;
	padding: 0 20px;
	margin: 0 auto;

`;

const SSearchResultsEmpty = styled.div`
	min-height: 50px;
	padding: 20px;
	max-width: 650px;
	margin: auto;
	text-align: center;
`;

//#endregion

//#region [Props]
type SearcResultProps = {
	searchExpression?: string;
	showSearchResult: boolean;
};
//#endregion
const noOfMoviesToShow = 6;

//#region [Component]
export default function SearchResult({ searchExpression, showSearchResult }: SearcResultProps) {
	const { searchLoading, searchLoaded, searchResult, locationResult } = useSearch(searchExpression, { active: !!searchExpression?.trim(), maxNumItems: noOfMoviesToShow + 1 })

	const hasSearchExpression = !!searchExpression?.trim();

	const _trackLocSearch = useCallback((locName: string) => trackSearch(locName, "Kinosted", searchExpression!), [searchExpression]);
	const _trackSearch = useCallback((id: string | number, title: string) => trackSearch(id, title, searchExpression!), [searchExpression]);

	return <SSearchResult>
		{showSearchResult && hasSearchExpression && <>
			{searchLoading && <SSearchResultsEmpty>
				<SuspenseFallback text='Henter resultater...' />
			</SSearchResultsEmpty>}

			{!searchLoading && searchResult.length === 0 && locationResult!.length === 0 && <SSearchResultsEmpty>
				Ingen treff
			</SSearchResultsEmpty>}

			{!searchLoading && (searchResult.length > 0 || locationResult!.length > 0) && <SSearchResultsList>
				{(locationResult?.length ?? 0) > 0 && <LocationResultList locationList={locationResult!} clickTracker={_trackLocSearch} />}
				{(searchResult?.length ?? 0) > 0 && <ResultPosterRow searchResult={searchResult as WatchableContentType[]} maxResults={noOfMoviesToShow} clickTracker={_trackSearch} />}
			</SSearchResultsList>}
		</>}
	</SSearchResult>;
}
//#endregion

//#region [Other] trackSearch
function trackSearch(id: string | number, title: string, searchExpression: string) {
	if (typeof window !== "undefined" && searchExpression?.trim().length > 0) {
		window!.dataLayer?.push({
			'event': 'fw_Quicksearch',
			'eventCategory': 'Hurtigsøk',
			'eventAction': `${title} | ${id}`,
			'eventLabel': searchExpression
		});
	}
}
//#endregion